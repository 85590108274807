import type { FC } from 'react';
import React, { useEffect, useRef, useContext } from 'react';
import Markdown from 'markdown-to-jsx';
import { useIntl } from 'react-intl-next';

import { useResizingHeight } from '@atlaskit/motion';
import { Box, Stack } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	AISummaryStreamingState,
	useAISummaryStreaming,
	useConfluencePageAri,
} from '@atlassian/ai-summary';
import type { AISummaryStreamingConfig, StreamError } from '@atlassian/ai-summary';
import { AIStreamLoading, AIBorder, AITelepointerWrapper } from '@atlassian/ai-components';
import { usePopupDwellTime } from '@atlassian/popup-dwell-time';
import type { AIEventsInstrumentationConfig } from '@atlassian/ai-analytics';
import {
	AIEventsInstrumentationProvider,
	useAIEventsInstrumentation,
} from '@atlassian/ai-analytics';

import { useSessionData } from '@confluence/session-data';
import {
	ExperienceTrackerContext,
	QUICK_SUMMARY_EXPERIENCE,
	QUICK_SUMMARY_WHITEBOARDS_EXPERIENCE,
} from '@confluence/experience-tracker';
import { fg } from '@confluence/feature-gating';

import { QUICK_SUMMARY_DIALOG_INTERACTION_METRIC } from '../perf.config';
import { useQuickSummaryAnalyticsProperties } from '../hooks/useQuickSummaryAnalyticsProperties';
import { AISummaryFooter } from '../shared-components/AISummaryFooter';
import { SmartsSummaryErrorComponent } from '../shared-components/SmartsSummaryErrorComponent';
import { QuickSummaryFollowUpContainer } from '../QuickSummaryFollowUpContainer';
import {
	MAX_SUMMARY_HEIGHT,
	SummaryPopupScrollableBody,
} from '../shared-components/SummaryPopupScrollableBody';
import { FeedbackCollectorPropertiesProvider } from '../shared-components/FeedbackCollectorPropertiesProvider';
import {
	QUICK_SUMMARY_FEEDBACK_ENTRYPOINT_ID,
	WHITEBOARDS_SUMMARY_FEEDBACK_ENTRYPOINT_ID,
} from '../feedbackEntrypointIds';
import { validateMarkdownByContentType } from '../effects/aiOutputValidation';

import { ExperienceStreamingSuccess } from './ExperienceStreamingSuccess';

export type QuickSummaryStreamingComponentProps = {
	contentId: string;
	spaceKey?: string;
	lastModifiedDate: string;
	source: string;
	contentPath: string;
	contentType: string;
	setIsOpen: (open: boolean) => void;
};

const QuickSummaryComponent: FC<QuickSummaryStreamingComponentProps> = ({
	contentId,
	spaceKey,
	lastModifiedDate,
	source,
	contentPath,
	contentType,
	setIsOpen,
}) => {
	const summaryRef = useRef<HTMLDivElement | null>(null);
	const { getDwellTime } = usePopupDwellTime(summaryRef);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { locale } = useIntl();
	const contentLastUpdated = Math.floor(new Date(lastModifiedDate).getTime() / 1000);

	const experienceTracker = useContext(ExperienceTrackerContext);

	const experienceTrackerName =
		contentType === 'whiteboard' ? QUICK_SUMMARY_WHITEBOARDS_EXPERIENCE : QUICK_SUMMARY_EXPERIENCE;

	const {
		trackAIInteractionInit,
		trackAIResultView,
		trackAIResultError,
		trackAIInteractionDismiss,
	} = useAIEventsInstrumentation();

	const { isRovoEnabled, cloudId } = useSessionData();

	const { pageAri } = useConfluencePageAri(cloudId, contentId, contentType);

	const { analyticsProperties } = useQuickSummaryAnalyticsProperties(
		contentLastUpdated,
		contentType,
	);

	const isStreamingDone = useRef(false);

	const setIsStreamingDone = () => {
		isStreamingDone.current = true;
	};

	const onStart = () => {
		experienceTracker.start({
			name: experienceTrackerName,
			attributes: {
				contentId,
			},
		});
		QUICK_SUMMARY_DIALOG_INTERACTION_METRIC.start();
		trackAIInteractionInit();
	};

	const onError = (reason: StreamError) => {
		QUICK_SUMMARY_DIALOG_INTERACTION_METRIC.stop();
		trackAIResultError({
			aiErrorCode: reason.message.status_code!!,
			aiErrorMessage: reason.message.message_template,
		});
		setIsStreamingDone();
	};

	useEffect(() => {
		return () => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'dwelled',
					actionSubject: 'quickSummary',
					source,
					actionSubjectId: 'quickSummaryDialog',
					attributes: {
						contentId,
						contentType,
						dwellTime: getDwellTime(),
						isAIMateSummary: true,
					},
				},
			}).fire();
			if (!isStreamingDone.current) {
				trackAIInteractionDismiss();
			}
		};
		// we only want to run this effect on un-mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let aiSummaryStreamingConfig: AISummaryStreamingConfig & { withRefetch?: false } = {
		ari: pageAri ?? '',
		agentNamedId: 'page_summaries_agent',
		product: 'confluence',
		experienceId: 'page-summaries',
		locale,
		summaryStyle: 'medium',
		summaryOutputMimetype: 'text/markdown-plus',
		delayChunkUpdate: { gap: 10, time: 20 },
		onStart,
		onError,
		onDone: setIsStreamingDone,
		featureFlags: {
			showFollowUps: isRovoEnabled,
		},
	};

	if (contentType === 'whiteboard') {
		aiSummaryStreamingConfig = {
			ari: pageAri ?? '',
			agentNamedId: 'whiteboard_summary_agent',
			product: 'confluence',
			experienceId: 'whiteboard-summary',
			locale,
			delayChunkUpdate: { gap: 10, time: 20 },
			onStart,
			onError,
			onDone: setIsStreamingDone,
		};
	}

	const { content, state, followUpContent, error } =
		useAISummaryStreaming(aiSummaryStreamingConfig);

	useEffect(() => {
		if (state === AISummaryStreamingState.FinalResponse && content) {
			const summaryHeight = summaryRef.current?.scrollHeight ?? 0;
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'quickSummaryDialog',
					attributes: {
						contentId,
						contentType,
						summaryLocale: 'en-US',
						contentLastUpdated,
						summaryHeight,
						maxSummaryHeight: MAX_SUMMARY_HEIGHT,
						isAIMateSummary: true,
						isValidSummary: validateMarkdownByContentType(contentType, content),
					},
				},
			}).fire();
			trackAIResultView();
			QUICK_SUMMARY_DIALOG_INTERACTION_METRIC.stop();
		}
	}, [
		createAnalyticsEvent,
		contentId,
		contentLastUpdated,
		content,
		contentType,
		state,
		isRovoEnabled,
		trackAIResultView,
	]);

	const renderPopupContent = () => {
		switch (state) {
			case AISummaryStreamingState.Initialization:
				return <AIStreamLoading />;
			case AISummaryStreamingState.Error:
				return (
					<SmartsSummaryErrorComponent
						analyticsProperties={analyticsProperties}
						errorFromApolloOrStream={error}
						contentId={contentId}
						experienceName={experienceTrackerName}
					/>
				);
			case AISummaryStreamingState.AnswerPart:
			case AISummaryStreamingState.FinalResponse:
			case AISummaryStreamingState.FollowUp:
				return (
					<Stack>
						<Box padding="space.250">
							<Stack space="space.250">
								<SummaryPopupScrollableBody
									ref={summaryRef}
									data-testid="quick-summary-body-content"
								>
									<AITelepointerWrapper
										hideTelepointer={state !== AISummaryStreamingState.AnswerPart}
									>
										<Markdown>{content}</Markdown>
									</AITelepointerWrapper>
								</SummaryPopupScrollableBody>
								<QuickSummaryFollowUpContainer
									content={content}
									contentPath={contentPath}
									setIsOpen={setIsOpen}
									isPageSummaryFollowUpEnabled={isRovoEnabled}
									followUpContent={followUpContent}
								/>
							</Stack>
						</Box>
						<AISummaryFooter
							analyticsProperties={analyticsProperties}
							contentId={contentId}
							spaceKey={spaceKey}
							contentType={contentType}
							summaryText={content}
							isHidden={state === AISummaryStreamingState.AnswerPart}
							showBetaLozenge={
								fg('cc_ai_whiteboard_summary_show_beta_lozenge') && contentType === 'whiteboard'
							}
						/>
						<ExperienceStreamingSuccess state={state} experienceName={experienceTrackerName} />
					</Stack>
				);
		}
	};

	return (
		<AIBorder loading={state === AISummaryStreamingState.Initialization}>
			<div {...useResizingHeight()}>{renderPopupContent()}</div>
		</AIBorder>
	);
};

export const QuickSummaryStreamingComponent = (props: QuickSummaryStreamingComponentProps) => {
	const aiAnalyticsConfig: AIEventsInstrumentationConfig = {
		product: 'confluence',
		subproduct: 'confluence',
		source: 'confluencePage',
		aiFeatureName: 'quickSummary',
		proactiveGeneratedAI: 0,
		userGeneratedAI: 1,
		additionalAttributes: { contentType: props.contentType },
	};

	const feedbackEntryPointID =
		props.contentType === 'whiteboard'
			? WHITEBOARDS_SUMMARY_FEEDBACK_ENTRYPOINT_ID
			: QUICK_SUMMARY_FEEDBACK_ENTRYPOINT_ID;

	return (
		<FeedbackCollectorPropertiesProvider entrypointId={feedbackEntryPointID}>
			<AIEventsInstrumentationProvider config={aiAnalyticsConfig}>
				<QuickSummaryComponent {...props} />
			</AIEventsInstrumentationProvider>
		</FeedbackCollectorPropertiesProvider>
	);
};
